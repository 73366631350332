<script setup>
import {ref} from "vue"

import {useAccountStore} from "@shared/stores/AccountStore"
import {useGameStore} from "@shared/stores/GameStore"
import {useWalletStore} from "@shared/stores/WalletStore"

const accountStore = useAccountStore()
const gameStore = useGameStore()
const walletStore = useWalletStore()

const menuContainer = ref(null)

const scrollMenu = (amount) => {
    menuContainer.value.scrollBy({
        left: amount,
        behavior: "smooth"
    })
}
</script>

<template>
    <div class="ngmNavigation desktop-only">
        <div class="navigationMenuComponent">
            <div class="divMenuContainer" ref="menuContainer" id="outsider">
                <div class="divMenuScroller">

                    <div
                        v-if="accountStore.isAuthenticated"
                        @click.prevent="walletStore.setWalletModalVisibility(true)"
                        class="divMenuItem btnOpenWallet"
                        data-index="0"
                        style="background-image: url('/core/img/navigationMenuImages/deposit.jpg');"
                    >
                        <div class="divMenuItemOverlay" style="background-color: rgb(12, 211, 93);"></div>
                        <a href="#" class="divMenuItemLabel">
                            Deposit
                        </a>
                    </div>

                    <router-link
                        :to="{name: 'webplay.game-providers'}"
                        v-slot="{href, navigate}"
                        custom
                    >
                        <div @click="navigate" class="divMenuItem" data-index="8"
                            style="background-image: url('/core/img/navigationMenuImages/gameProviders.jpg');">
                            <div class="divMenuItemOverlay" style="background-color: rgb(127, 51, 161);"></div>
                            <a :href="href" class="divMenuItemLabel">Game Providers</a>
                        </div>
                    </router-link>

                    <router-link
                        v-if="accountStore.isAuthenticated"
                        :to="{name: 'webplay.favourites'}"
                        v-slot="{href, navigate}"
                        custom
                    >
                        <div
                            class="divMenuItem auth"
                            data-index="2"
                            style="background-image: url('/core/img/navigationMenuImages/favourites.jpg'); display: block;"
                            @click="navigate"
                        >
                            <div class="divMenuItemOverlay" style="background-color: rgb(0, 255, 255);"></div>
                            <a :href="href" class="divMenuItemLabel">
                                Favourites
                            </a>
                        </div>
                    </router-link>

                    <router-link
                        :to="{name: 'webplay.all'}"
                        v-slot="{href, navigate}"
                        custom
                    >
                        <div class="divMenuItem" data-index="4"
                            style="background-image: url('/core/img/navigationMenuImages/allGames.jpg');" @click="navigate">
                            <div class="divMenuItemOverlay" style="background-color: rgb(170, 0, 0);"></div>
                            <a :href="href" class="divMenuItemLabel">All Games</a>
                        </div>
                    </router-link>

                    <router-link
                        :to="{name: 'webplay.new-games'}"
                        v-slot="{href, navigate}"
                        custom
                    >
                        <div @click="navigate" class="divMenuItem" data-index="6"
                            style="background-image: url('/core/img/navigationMenuImages/newGames.jpg');">
                            <div class="divMenuItemOverlay" style="background-color: rgb(177, 0, 28);"></div>
                            <a :href="href" class="divMenuItemLabel">New Games</a>
                        </div>
                    </router-link>

                    <router-link
                        v-if="accountStore.isAuthenticated"
                        :to="{name: 'webplay.recent-games'}"
                        v-slot="{href, navigate}"
                        custom
                    >
                        <div
                            class="divMenuItem auth"
                            data-index="1"
                            style="background-image: url('/core/img/navigationMenuImages/recentlyPlayed.jpg'); display: block;"
                            @click="navigate"
                        >
                            <div class="divMenuItemOverlay" style="background-color: rgb(0, 136, 0);"></div>
                            <a :href="href" class="divMenuItemLabel">
                                Recent Games
                            </a>
                        </div>
                    </router-link>

                    <router-link
                        :to="{name: 'webplay.most-popular'}"
                        v-slot="{href, navigate}"
                        custom
                    >
                        <div
                            class="divMenuItem" data-index="3"
                            style="background-image: url('/core/img/navigationMenuImages/mostPopular.jpg');"
                            @click="navigate"
                        >

                            <div class="divMenuItemOverlay" style="background-color: rgb(255, 0, 255);"></div>
                            <a :href="href" class="divMenuItemLabel">
                                Most Popular
                            </a>
                        </div>
                    </router-link>

                    <router-link
                        :to="{name: 'webplay.category', params: { category: 'slots' }}"
                        v-slot="{href, navigate}"
                        custom
                    >
                        <div @click="navigate" class="divMenuItem" data-index="5"
                             style="background-image: url('/core/img/navigationMenuImages/slotGames.jpg');">
                            <div class="divMenuItemOverlay" style="background-color: rgb(170, 87, 0);"></div>
                            <a :href="href" class="divMenuItemLabel">Slot Games</a>
                        </div>
                    </router-link>

                    <router-link
                        :to="{name: 'webplay.live-casino'}"
                        v-slot="{href, navigate}"
                        custom
                    >
                        <div @click="navigate" class="divMenuItem" data-index="9"
                            style="background-image: url('/core/img/navigationMenuImages/liveCasino.jpg');">
                            <div class="divMenuItemOverlay" style="background-color: rgb(0, 0, 255);"></div>
                            <a :href="href" class="divMenuItemLabel">Live Casino</a>
                        </div>
                    </router-link>

                    <router-link
                        :to="{name: 'webplay.category', params: { category: 'table-games' }}"
                        v-slot="{href, navigate}"
                        custom
                    >
                        <div @click="navigate" class="divMenuItem" data-index="7"
                             style="background-image: url('/core/img/navigationMenuImages/tableGames.jpg');">
                            <div class="divMenuItemOverlay" style="background-color: rgb(52, 70, 93);"></div>
                            <a :href="href" class="divMenuItemLabel">Table Games</a>
                        </div>
                    </router-link>

                    <router-link
                        :to="{name: 'webplay.category', params: { category: 'live-blackjack' }}"
                        v-slot="{href, navigate}"
                        custom
                    >
                        <div @click="navigate" class="divMenuItem" data-index="11"
                            style="background-image: url('/core/img/navigationMenuImages/liveBlackjack.jpg');">
                            <div class="divMenuItemOverlay" style="background-color: rgb(0, 0, 0);"></div>
                            <a :href="href" class="divMenuItemLabel">Live Blackjack</a>
                        </div>
                    </router-link>

                    <router-link
                        :to="{name: 'webplay.category', params: { category: 'live-roulette' }}"
                        v-slot="{href, navigate}"
                        custom
                    >
                        <div @click="navigate" class="divMenuItem" data-index="12"
                            style="background-image: url('/core/img/navigationMenuImages/liveRoulette.jpg');">
                            <div class="divMenuItemOverlay" style="background-color: rgb(85, 85, 85);"></div>
                            <a :href="href" class="divMenuItemLabel">Live Roulette</a>
                        </div>
                    </router-link>

                    <router-link
                        :to="{name: 'webplay.featured-games'}"
                        v-slot="{href, navigate}"
                        custom
                    >
                        <div @click="navigate" class="divMenuItem" data-index="10"
                             style="background-image: url('/core/img/navigationMenuImages/featuredGames.jpg');">
                            <div class="divMenuItemOverlay" style="background-color: rgb(103, 0, 103);"></div>
                            <a :href="href" class="divMenuItemLabel">Featured Games</a>
                        </div>
                    </router-link>
                </div>
            </div>
            <div class="btnScrollerMenuLeft" @click="() => scrollMenu(-140)"></div>
            <div class="btnScrollerMenuRight" @click="() => scrollMenu(140)"></div>
        </div>
    </div>

    <div class="nmmNavigationMobile mobile-only">
        <div class="navigationMenuMobileComponent">
            <div class="divMenuContainer">
                <div class="divMenuScroller" style="width: 1400px;">
                    <div v-if="accountStore.isAuthenticated" @click.prevent="walletStore.setWalletModalVisibility(true)" class="divMenuItem" data-index="0" style="background-image: url('/core/img/navigationMenuIcons/deposit.png');">
                        <a href="#" class="divMenuItemLabel">Deposit</a>
                    </div>

                    <div class="divMenuItem" data-index="8"
                        style="background-image: url('/core/img/navigationMenuIcons/gameProviders.png');">
                        <a href="/webplay/game-providers" class="divMenuItemLabel">Game Providers</a>
                    </div>

                    <router-link
                        v-if="accountStore.isAuthenticated"
                        :to="{name: 'webplay.favourites'}"
                        v-slot="{href, navigate}"
                        custom
                    >
                        <div @click="navigate" class="divMenuItem auth" data-index="2" style="background-image: url('/core/img/navigationMenuIcons/favourites.png'); display: block;">
                            <a :href="href" class="divMenuItemLabel">Favourites</a>
                        </div>
                    </router-link>

                    <router-link
                        :to="{name: 'webplay.all'}"
                        v-slot="{href, navigate}"
                        custom
                    >
                        <div @click="navigate" class="divMenuItem" data-index="4"
                            style="background-image: url('/core/img/navigationMenuIcons/allGames.png');">
                            <a :href="href" class="divMenuItemLabel">All Games</a>
                        </div>
                    </router-link>

                    <router-link
                        :to="{name: 'webplay.new-games'}"
                        v-slot="{href, navigate}"
                        custom
                    >
                        <div @click="navigate" class="divMenuItem" data-index="6" style="background-image: url('/core/img/navigationMenuIcons/newGames.png');">
                            <a :href="href" class="divMenuItemLabel">New Games</a>
                        </div>
                    </router-link>

                    <router-link
                        v-if="accountStore.isAuthenticated"
                        :to="{name: 'webplay.recent-games'}"
                        v-slot="{href, navigate}"
                        custom
                    >
                        <div @click="navigate" class="divMenuItem auth" data-index="1" style="background-image: url('/core/img/navigationMenuIcons/recentlyPlayed.png'); display: block;">
                            <a :href="href" class="divMenuItemLabel">Recent Games</a>
                        </div>
                    </router-link>

                    <router-link
                        :to="{name: 'webplay.most-popular'}"
                        v-slot="{href, navigate}"
                        custom
                    >
                        <div @click="navigate" class="divMenuItem" data-index="3" style="background-image: url('/core/img/navigationMenuIcons/mostPopular.png');">
                            <a :href="href" class="divMenuItemLabel">Most Popular</a>
                        </div>
                    </router-link>

                    <router-link
                        :to="{name: 'webplay.category', params: { category: 'slots' }}"
                        v-slot="{href, navigate}"
                        custom
                    >
                        <div @click="navigate" class="divMenuItem" data-index="5" style="background-image: url('/core/img/navigationMenuIcons/slotGames.png');">
                            <a :href="href" class="divMenuItemLabel">Slot Games</a>
                        </div>
                    </router-link>

                    <router-link
                        :to="{name: 'webplay.live-casino'}"
                        v-slot="{href, navigate}"
                        custom
                    >
                        <div @click="navigate" class="divMenuItem" data-index="9" style="background-image: url('/core/img/navigationMenuIcons/liveCasino.png');">
                            <a :href="href" class="divMenuItemLabel">Live Casino</a>
                        </div>
                    </router-link>

                    <router-link
                        :to="{name: 'webplay.category', params: { category: 'table-games' }}"
                        v-slot="{href, navigate}"
                        custom
                    >
                        <div @click="navigate" class="divMenuItem" data-index="7" style="background-image: url('/core/img/navigationMenuIcons/tableGames.png');">
                            <a :href="href" class="divMenuItemLabel">Table Games</a>
                        </div>
                    </router-link>

                    <router-link
                        :to="{name: 'webplay.category', params: { category: 'live-blackjack' }}"
                        v-slot="{href, navigate}"
                        custom
                    >
                        <div @click="navigate" class="divMenuItem" data-index="11" style="background-image: url('/core/img/navigationMenuIcons/liveBlackjack.png');">
                            <a :href="href" class="divMenuItemLabel">Live Blackjack</a>
                        </div>
                    </router-link>

                    <router-link
                        :to="{name: 'webplay.category', params: { category: 'live-roulette' }}"
                        v-slot="{href, navigate}"
                        custom
                    >
                        <div @click="navigate" class="divMenuItem" data-index="12" style="background-image: url('/core/img/navigationMenuIcons/liveRoulette.png');">
                            <a :href="href" class="divMenuItemLabel">Live Roulette</a>
                        </div>
                    </router-link>

                    <router-link
                        :to="{name: 'webplay.featured-games'}"
                        v-slot="{href, navigate}"
                        custom
                    >
                        <div @click="navigate" class="divMenuItem" data-index="10" style="background-image: url('/core/img/navigationMenuIcons/featuredGames.png');">
                            <a :href="href" class="divMenuItemLabel">Featured Games</a>
                        </div>
                    </router-link>
                </div>
            </div>
        </div>
    </div>
</template>

<style>
    .divMenuScroller {
        display: flex;
        width: auto !important;
    }
    .divMenuItem {
        flex-shrink: 0;
    }
</style>
