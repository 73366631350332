<script setup>
import { useAccountStore } from "@shared/stores/AccountStore"
import { useCouponStore } from "@shared/stores/CouponStore"
import { useLoyaltyChestStore } from "@shared/stores/LoyaltyChestStore"
import { reactive } from "vue"

const accountStore = useAccountStore()
const couponStore = useCouponStore()
const loyaltyChestStore = useLoyaltyChestStore()

const state = reactive({
    isOpen: false,
    isGamesOpen: false,
    isShowMoreOpen: false,
})

const handleCloseMenu = () => {
    state.isOpen = false
}
</script>

<template>
    <div id="divAppMenu" :class="{
        'menuOpen': state.isOpen
    }">
        <div id="divMenuOverlay" @click="state.isOpen = false"></div>
        <div id="divMenuHandel" @click="state.isOpen = !state.isOpen">
            <div></div>
        </div>
        <router-link @click="handleCloseMenu" :to="{ name: 'home' }" id="imgMenuLogo">
            <img src="/images/playlive-logo.svg" alt="Playlive Casino" />
        </router-link>
        <div class="divMenuContainer">
            <ul id="ulMainMenu">
                <li class="liMenuItem">
                    <router-link @click="handleCloseMenu" :to="{ name: 'home' }">Home</router-link>
                </li>
                <li class="liMenuItem">
                    <router-link @click="handleCloseMenu" :to="{ name: 'webplay.live-casino' }">LIVE
                        CASINO</router-link>
                </li>
                <li :class="{
                    'liMenuItem': true,
                    'subMenuOpen': state.isGamesOpen
                }">
                    <router-link @click="handleCloseMenu" :to="{ name: 'webplay.all' }">GAMES</router-link>
                    <div class="divToggleSubMenu" @click="state.isGamesOpen = !state.isGamesOpen" />
                    <ul class="ulSubMenu">
                        <li class="liSubMenuItem">
                            <router-link @click="handleCloseMenu" class="aSubMenuItem"
                                :to="{ name: 'platform.desktop' }">DESKTOP GAMES</router-link>
                        </li>
                        <li class="liSubMenuItem">
                            <router-link @click="handleCloseMenu" class="aSubMenuItem"
                                :to="{ name: 'platform.mobile' }">MOBILE GAMES</router-link>
                        </li>
                        <li class="liSubMenuItem">
                            <router-link @click="handleCloseMenu" class="aSubMenuItem"
                                :to="{ name: 'webplay.category', params: { category: 'slots' } }">VIDEO
                                SLOTS</router-link>
                        </li>
                        <li class="liSubMenuItem">
                            <router-link @click="handleCloseMenu" class="aSubMenuItem"
                                :to="{ name: 'webplay.category', params: { category: 'poker' } }">POKER
                                GAMES</router-link>
                        </li>
                        <li class="liSubMenuItem">
                            <router-link @click="handleCloseMenu" class="aSubMenuItem"
                                :to="{ name: 'webplay.category', params: { category: 'live-blackjack' } }">BLACKJACK
                                GAMES</router-link>
                        </li>
                        <li class="liSubMenuItem">
                            <router-link @click="handleCloseMenu" class="aSubMenuItem"
                                :to="{ name: 'webplay.category', params: { category: 'keno' } }">KENO
                                GAMES</router-link>
                        </li>
                        <li class="liSubMenuItem">
                            <router-link @click="handleCloseMenu" class="aSubMenuItem"
                                :to="{ name: 'webplay.category', params: { category: 'baccarat' } }">BACCARAT
                                GAMES</router-link>
                        </li>
                        <li class="liSubMenuItem">
                            <router-link @click="handleCloseMenu" class="aSubMenuItem"
                                :to="{ name: 'bonus-play-games' }">BONUS GAMES</router-link>
                        </li>
                        <li class="liSubMenuItem">
                            <router-link @click="handleCloseMenu" class="aSubMenuItem"
                                :to="{ name: 'webplay.category', params: { category: 'live-roulette' } }">ROULETTE
                                GAMES</router-link>
                        </li>
                        <li class="liSubMenuItem">
                            <router-link @click="handleCloseMenu" class="aSubMenuItem"
                                :to="{ name: 'free-bet-support-games' }">FREE BET GAMES</router-link>
                        </li>
                        <li class="liSubMenuItem">
                            <router-link @click="handleCloseMenu" class="aSubMenuItem"
                                :to="{ name: 'webplay.category', params: { category: 'video-bingo' } }">VIDEO
                                BINGO</router-link>
                        </li>
                    </ul>
                </li>
                <li class="liMenuItem">
                    <router-link @click="handleCloseMenu" :to="{ name: 'tournaments' }">TOURNAMENTS</router-link>
                </li>
                <li v-if="accountStore.isAuthenticated" class="liMenuItem" @click="() => {
                    handleCloseMenu()
                    couponStore.setCouponModalVisibility(true)
                }">
                    REDEEM
                </li>
                <li class="liMenuItem">
                    <router-link @click="handleCloseMenu" class="aMenuItem"
                        :to="{ name: 'promotions' }">PROMOTIONS</router-link>
                </li>
                <li v-if="appHasLoyalty && accountStore.isAuthenticated" class="liMenuItem" @click="() => {
                    handleCloseMenu()
                    loyaltyChestStore.setLoyaltyChestModalVisible(true)
                }">
                    LOYALTY CHESTS
                </li>
                <li class="liMenuItem">
                    <router-link @click="handleCloseMenu" class="aMenuItem" :to="{ name: 'news' }">NEWS</router-link>
                </li>
                <li class="liMenuItem">
                    <router-link @click="handleCloseMenu" class="aMenuItem" :to="{ name: 'how-to' }">HOW
                        TO</router-link>
                </li>
                <li class="liMenuItem">
                    <router-link @click="handleCloseMenu" class="aMenuItem" :to="{ name: 'about-us' }">ABOUT
                        US</router-link>
                </li>
                <li id="showMoreMenu" class="liMenuItem liMenuItem--showMore">
                    <div class="ulSubMenu__wrapper">
                        <span class="divShowMoreIcon fa fa-ellipsis-h"
                            @click="state.isShowMoreOpen = !state.isShowMoreOpen"></span>
                        <ul class="ulSubMenu ulSubMenu--showMore">
                            <li v-if="appHasLoyalty && accountStore.isAuthenticated" class="liSubMenuItem" @click="() => {
                                handleCloseMenu()
                                loyaltyChestStore.setLoyaltyChestModalVisible(true)
                            }">
                                LOYALTY CHESTS
                            </li>
                            <li class="liSubMenuItem">
                                <router-link @click="handleCloseMenu" class="aSubMenuItem"
                                    :to="{ name: 'news' }">NEWS</router-link>
                            </li>
                            <li class="liSubMenuItem">
                                <router-link @click="handleCloseMenu" class="aSubMenuItem" :to="{ name: 'how-to' }">HOW
                                    TO</router-link>
                            </li>
                            <li class="liSubMenuItem">
                                <router-link @click="handleCloseMenu" class="aSubMenuItem"
                                    :to="{ name: 'about-us' }">ABOUT US</router-link>
                            </li>
                        </ul>
                    </div>
                </li>
            </ul>
        </div>
    </div>
</template>

<style lang="scss" scoped>
#divAppMenu {
    margin-right: 20px;

    @media (max-width: 800px) {
        margin-right: 0;
    }
}

#divMenuOverlay {
    @media (min-width: 800px) {
        display: none;
    }
}

#ulMainMenu {
    display: flex;
    flex-wrap: nowrap;

    @media (max-width: 800px) {
        flex-wrap: wrap;
        border-bottom: 0 none !important;
    }

    @media (min-width: 800px) and (max-width: 1300px) {
        >.liMenuItem:not(:nth-child(-n + 6)):not(:last-child) {
            display: none !important;
        }
    }

    .liMenuItem {
        flex-shrink: 0;

        &:hover {
            >a {
                color: #9c13b0 !important;
            }
        }

        @media (max-width: 900px) {
            padding: 0 4px;
        }

        @media (max-width: 800px) {
            width: 100%;
            padding: 0 20px;
        }
    }

    .ulSubMenu {
        z-index: 1;

        &--showMore {
            .liSubMenuItem {
                padding: 0 15px;
                color: #fff;
                white-space: nowrap;
                transition: color 0.25s;

                &:hover {
                    color: #9c13b0;
                }
            }

            .aSubMenuItem {
                padding: 0;
            }
        }
    }
}

#showMoreMenu {
    display: flex !important;

    @media (max-width: 800px) {
        display: none !important;
    }

    @media (min-width: 1300px) {
        display: none !important;
    }
}

#divMenuHandel {
    @media (max-width: 500px) {
        margin-left: -7px;
    }
}

#imgMenuLogo {
    @media (max-width: 500px) {
        width: auto !important;
    }
}
</style>
